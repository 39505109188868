import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showMobileFilters: false,
  activityFeedFilter: {
    dateRange: {
      from: null,
      to: null
    },
    campaignId: null,
    candidateId: null
  }
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setActivityFeedFilter(state, { payload }) {
      state.activityFeedFilter = {
        ...state.activityFeedFilter,
        ...payload
      };
    },
    toggleMobileFilters(state) {
      state.showMobileFilters = !state.showMobileFilters;
    }
  }
});

export const { setActivityFeedFilter, toggleMobileFilters } =
  DashboardSlice.actions;

export default DashboardSlice.reducer;
