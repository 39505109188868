import DateRangePicker from '@src/components/activity-feed/DateRangePicker/DateRangePicker';
import Dropdown from '@src/components/shared/Dropdown';
import { setActivityFeedFilter } from '@src/store/slices/Dashboard';
import { useDispatch } from 'react-redux';
import { useGetCampaignsQuery } from '@src/queries/Campaign';
import { useSelector } from 'react-redux';
import { selectActivityFeedFilter } from '@src/store/selectors/Dashboard';
import { useGetCandidatesQuery } from '@src/queries/Candidate';
import { getFullName } from '@src/utils/common';

const HomeDesktopActivityFeedFilters = () => {
  const dispatch = useDispatch();

  const { data: campaigns, isLoading: getCampaignLoading } =
    useGetCampaignsQuery();

  const { data: candidates, isLoading: getCandidateLoading } =
    useGetCandidatesQuery();

  const activityFeedFilter = useSelector(selectActivityFeedFilter);

  const campaignOptions = (() => {
    if (!campaigns || getCampaignLoading) return [];

    const options = campaigns.map((campaign) => ({
      campaignName: campaign.campaign_name,
      id: campaign.campaign_id
    }));

    return [{ campaignName: 'All Campaigns', id: null }, ...options];
  })();

  const candidateOptions = (() => {
    if (!candidates || getCandidateLoading) return [];

    const options = candidates.map((candidate) => ({
      name: getFullName(candidate.first_name, candidate.last_name),
      id: candidate.candidate_id
    }));

    return [{ name: 'All Candidates', id: null }, ...options];
  })();

  return (
    <div className="flex gap-4 relative">
      <Dropdown
        disabled={getCampaignLoading}
        options={campaignOptions}
        className="w-40"
        onChange={(campaign) => {
          dispatch(
            setActivityFeedFilter({
              campaignId: campaign?.id
            })
          );
        }}
        selectedOption={campaignOptions.find(
          (campaign) => campaign.id === activityFeedFilter.campaignId
        )}
        labelKey="campaignName"
      />
      <Dropdown
        disabled={getCandidateLoading}
        options={candidateOptions}
        className="w-40"
        onChange={(candidate) => {
          dispatch(
            setActivityFeedFilter({
              candidateId: candidate?.id
            })
          );
        }}
        selectedOption={candidateOptions.find(
          (candidate) => candidate.id === activityFeedFilter.candidateId
        )}
        labelKey="name"
      />
      <DateRangePicker className="w-[200px]" />
    </div>
  );
};

export default HomeDesktopActivityFeedFilters;
