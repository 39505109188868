import {
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer
} from 'recharts';

import { ReactComponent as InfoIcon } from '@src/assets/images/information-symbol.svg';
import Tooltip from '@src/components/Tooltip';

const EmptyDashboard = () => {
  const PIE_CHART_COLORS = ['#0A3054', '#062339'];

  return (
    <div className="flex gap-4 w-full h-[calc(100vh-50px)] p-2.5 absolute top-0 bottom-28">
      <div className="flex flex-col gap-4 justify-stretch">
        <div className="flex flex-col gap-3 border border-[#0A3054] p-2.5 rounded-md relative">
          <div className="absolute w-6 h-6 top-2.5 right-2.5">
            <Tooltip
              content="Creating, managing and analyzing your job campaigns is so easy with the Trusted Dev portal. Simply setup a job campaign and we do the rest of the work presenting you with rigorously pre-vetted candidates that match your hiring profile!"
              contentClassName="w-[200px]">
              <InfoIcon className="fill-black bg-[#FFEF86] p-1 rounded-full size-6" />
            </Tooltip>
          </div>
          {[...Array(4)].map((_, index) => (
            <div
              key={index}
              className="w-[280px] h-[80px] bg-[#0C1A2B] flex gap-2 p-1 rounded-md">
              <div className="bg-[#0A3054] h-full w-6 rounded-l-md" />
              <div className="flex m-2 flex-col justify-between gap-2 w-full">
                <div className="flex justify-between w-full">
                  <div className="w-[100px] h-2.5 rounded-md bg-[#0A3054]" />
                  <div className="w-[70px] h-2.5 rounded-md bg-[#0A3054]" />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-2.5 rounded-md bg-[#0A3054]" />
                  <div className="w-full h-2.5 rounded-md bg-[#0A3054]" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col border border-[#0A3054] p-2.5 rounded-md flex-1 overflow-hidden relative">
          <div className="absolute z-10 w-6 h-6 top-2.5 right-2.5">
            <Tooltip
              content="Manage your candidate interviews from end to end inside of the Trusted Dev portal. We utilize cutting edge AI and machine learning technologies to enhance your candidate interview experience."
              contentClassName="w-[200px]">
              <InfoIcon className="fill-black bg-[#FFEF86] p-1 rounded-full size-6" />
            </Tooltip>
          </div>
          <div className="bg-[#0C1A2B] rounded-md overflow-hidden">
            {[...Array(20)].map((_, index) => (
              <div
                key={index}
                className="w-[280px] flex flex-col gap-3 relative py-4 pr-3 justify-between">
                <div className="flex justify-between w-full">
                  <div className="w-[150px] h-[20px] bg-[#0A3054] rounded-r-2xl" />
                  <div className="w-[70px] h-[20px] bg-[#0A3054] rounded-2xl" />
                </div>
                <div className="flex gap-2 items-center ml-3">
                  <div className="rounded-full w-[42px] h-[42px] bg-[#0A3054]" />
                  <div className="w-[100px] h-[16px] bg-[#0A3054] rounded-2xl" />
                </div>
                <div className="absolute rounded-full w-[28px] h-[28px] bg-[#0A3054] -right-2 top-1/2 -translate-y-1/2" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-1 relative">
        <div className="absolute z-10 w-6 h-6 top-2.5 right-2.5">
          <Tooltip
            content="Analyze the metrics of your hiring campaigns and candidate performance over time. Reduce hiring risk, costs and increase efficiency by utilizing our deep data insights."
            contentClassName="w-[200px]">
            <InfoIcon className="fill-black bg-[#FFEF86] p-1 rounded-full size-6" />
          </Tooltip>
        </div>
        <div className="border flex-1 self-end border-[#0A3054] w-[320px] p-2.5 rounded-md">
          <div className="flex flex-col justify-between flex-1 gap-5 bg-[#0C1A2B] p-2 rounded-md h-full w-full">
            <div className="flex flex-col gap-4">
              <div className="bg-[#0A3054] h-[30px] w-full rounded-md" />
              <div className="flex gap-2 justify-between">
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
              </div>
            </div>
            <div className="flex gap-4 justify-between items-center mx-2 my-8">
              <ResponsiveContainer
                width="100%"
                height="100%"
                className="h-[200px]">
                <PieChart width={300} height={300}>
                  <Pie
                    data={[
                      {
                        name: 'A',
                        value: 400
                      },
                      {
                        name: 'B',
                        value: 80
                      }
                    ]}
                    cx="50%"
                    cy="50%"
                    outerRadius={70}
                    startAngle={0}
                    endAngle={360}
                    stroke="none"
                    dataKey="value">
                    {[
                      {
                        name: 'A',
                        value: 400
                      },
                      {
                        name: 'B',
                        value: 80
                      }
                    ].map((_entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className="flex gap-2 items-end">
                <div className="w-6 h-28 bg-[#0A3054] rounded-md" />
                <div className="w-6 h-16 bg-[#0A3054] rounded-md" />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="bg-[#0A3054] h-[30px] w-full rounded-md" />
              <div className="flex gap-2 justify-between">
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
                <div className="size-[50px] rounded-md bg-[#0A3054]" />
              </div>
            </div>
          </div>
        </div>
        <div className="border h-[23%] w-full rounded-md border-[#0A3054] p-2.5 relative">
          <div className="absolute z-10 w-6 h-6 top-2.5 right-2.5">
            <Tooltip
              content="Leverage the power of data analytics to make hiring or campaign decisions with our graphs and charts."
              contentClassName="w-[500px]">
              <InfoIcon className="fill-black bg-[#FFEF86] p-1 rounded-full size-6" />
            </Tooltip>
          </div>
          <div className="bg-[#0C1A2B] rounded-md h-full w-full">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={[
                  {
                    uv: 0
                  },
                  {
                    uv: 6
                  },
                  {
                    uv: 1
                  },
                  {
                    uv: 4
                  },
                  {
                    uv: 2
                  },
                  {
                    uv: 6
                  },
                  {
                    uv: 1
                  },
                  {
                    uv: 6
                  },
                  {
                    uv: 1
                  },
                  {
                    uv: 6
                  },
                  {
                    uv: 0
                  },
                  {
                    uv: 6
                  }
                ]}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                }}>
                <Area
                  stroke="none"
                  type="basis"
                  dataKey="uv"
                  stackId="1"
                  fill="#0A3054"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyDashboard;
