import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  parseISO,
  add,
  sub,
  format,
  differenceInYears
} from 'date-fns';
import config from '../config';
import {
  GRAPH_PERIOD,
  thisWeekFragments,
  thisMonthFragments,
  thisYearFragments
} from '../constants/horizontal-graph';

export const getChartDataByVote = (period, candidates) => {
  const {
    candidate: { profileStatus }
  } = config;

  let series = [];

  if (candidates.length === 0) {
    return {
      series: []
    };
  }

  switch (period) {
    case GRAPH_PERIOD.LAST_3_DAYS: {
      const startDay = startOfDay(sub(new Date(), { days: 2 }));

      series = Array.from(Array(3).keys()).map((day) => {
        const currentDay = add(startDay, { days: day });

        const candidatesInDay = candidates.filter((candidate) => {
          const isInDay =
            parseISO(candidate.date) > currentDay &&
            parseISO(candidate.date) < add(currentDay, { days: 1 });
          return isInDay;
        });

        const name = format(currentDay, 'EEE');

        return candidatesInDay.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_WEEK: {
      const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

      series = thisWeekFragments.map((fragment) => {
        const currentFragmentTime = add(startWeek, fragment);

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { days: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        const name = format(sub(currentFragmentTime, { days: 1 }), 'EEE');

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_MONTH: {
      const startMonth = startOfMonth(new Date());

      series = thisMonthFragments.map((fragment) => {
        const currentFragmentTime = add(startMonth, fragment);

        const name = format(sub(currentFragmentTime, { days: 1 }), 'd');

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { days: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          { name, votedUp: 0, votedDown: 0 }
        );
      });

      break;
    }
    case GRAPH_PERIOD.THIS_YEAR: {
      const startYear = startOfYear(new Date());

      series = thisYearFragments.map((fragment) => {
        const currentFragmentTime = add(startYear, fragment);

        const candidatesInFragment = candidates.filter((candidate) => {
          const isInFragment =
            parseISO(candidate.date) >
              sub(currentFragmentTime, { months: 1 }) &&
            parseISO(candidate.date) < currentFragmentTime;
          return isInFragment;
        });

        const name = format(
          sub(currentFragmentTime, { months: 1 }),
          'LLL'
        );

        return candidatesInFragment.reduce(
          (acc, candidate) => {
            switch (candidate.profile_status) {
              case profileStatus.VOTED_UP:
                acc.votedUp++;
                break;
              case profileStatus.VOTED_DOWN:
                acc.votedDown++;
                break;
              default:
                break;
            }
            return acc;
          },
          {
            name,
            votedUp: 0,
            votedDown: 0
          }
        );
      });

      break;
    }
    case GRAPH_PERIOD.ALL_TIME: {
      const sortedCandidatesByTime = candidates.sort((a, b) =>
        a.date > b.date ? 1 : -1
      );

      const startYear = startOfYear(
        parseISO(sortedCandidatesByTime[0].date)
      );

      const lastYear = startOfYear(
        parseISO(
          sortedCandidatesByTime[sortedCandidatesByTime.length - 1].date
        )
      );

      const yearDiff =
        Math.abs(differenceInYears(lastYear, startYear)) + 1;

      if (yearDiff > 3) {
        series = Array.from({ length: yearDiff }, (_, i) => {
          const currentYear = add(startYear, { years: i });

          const candidatesInYear = candidates.filter((candidate) => {
            const isInYear =
              parseISO(candidate.date) > currentYear &&
              parseISO(candidate.date) < add(currentYear, { years: 1 });
            return isInYear;
          });

          const name = format(currentYear, 'yyyy');

          return candidatesInYear.reduce(
            (acc, candidate) => {
              switch (candidate.profile_status) {
                case profileStatus.VOTED_UP:
                  acc.votedUp++;
                  break;
                case profileStatus.VOTED_DOWN:
                  acc.votedDown++;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              name,
              votedUp: 0,
              votedDown: 0
            }
          );
        });
      } else {
        const quarterDiff = yearDiff * 4;

        series = Array.from({ length: quarterDiff }, (_, i) => {
          const currentQuarter = add(startYear, { months: i * 3 });

          const candidatesInQuarter = candidates.filter((candidate) => {
            const isInQuarter =
              parseISO(candidate.date) > currentQuarter &&
              parseISO(candidate.date) <
                add(currentQuarter, { months: 3 });
            return isInQuarter;
          });

          const name = format(currentQuarter, 'yyyy QQQ');

          return candidatesInQuarter.reduce(
            (acc, candidate) => {
              switch (candidate.profile_status) {
                case profileStatus.VOTED_UP:
                  acc.votedUp++;
                  break;
                case profileStatus.VOTED_DOWN:
                  acc.votedDown++;
                  break;
                default:
                  break;
              }
              return acc;
            },
            {
              name,
              votedUp: 0,
              votedDown: 0
            }
          );
        });
      }

      break;
    }
    default:
      series = [];
  }

  return {
    series
  };
};

export const getChartDataByWorkedHours = (period, timeSheets) => {
  let series = [];

  const timeSheetsArray = Object.entries(timeSheets).map(
    ([date, value]) => ({
      date,
      value
    })
  );

  if (timeSheetsArray.length === 0) {
    return {
      series
    };
  }

  // timeSheets is an object with the following structure:
  // {
  //   2023-08-01: 300,
  //   2023-08-02: 200,
  //   2023-08-03: 100,
  //   ...
  // }
  switch (period) {
    case GRAPH_PERIOD.LAST_3_DAYS: {
      const startDay = startOfDay(sub(new Date(), { days: 2 }));

      series = Array.from(Array(3).keys()).map((day) => {
        const currentDay = add(startDay, { days: day });

        const timeSheetsInDay = timeSheetsArray.filter((timeSheet) => {
          const isInDay =
            parseISO(timeSheet.date) > currentDay &&
            parseISO(timeSheet.date) < add(currentDay, { days: 1 });
          return isInDay;
        });

        const name = format(currentDay, 'EEE');

        const sumWorkedHours = timeSheetsInDay.reduce(
          (acc, workedHours) => acc + workedHours.value,
          0
        );

        return {
          name,
          workedHours: sumWorkedHours
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_WEEK: {
      const startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

      series = thisWeekFragments.map((fragment) => {
        const currentFragmentTime = add(startWeek, fragment);

        const timeSheetsInFragment = timeSheetsArray.filter(
          (timeSheet) => {
            const isInFragment =
              parseISO(timeSheet.date) >
                sub(currentFragmentTime, { days: 1 }) &&
              parseISO(timeSheet.date) < currentFragmentTime;
            return isInFragment;
          }
        );

        const name = format(sub(currentFragmentTime, { days: 1 }), 'EEE');

        const sumWorkedHours = timeSheetsInFragment.reduce(
          (acc, workedHours) => acc + workedHours.value,
          0
        );

        return {
          name,
          workedHours: sumWorkedHours
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_MONTH: {
      const startMonth = startOfMonth(new Date());

      series = thisMonthFragments.map((fragment) => {
        const currentFragmentTime = add(startMonth, fragment);

        const timeSheetsInFragment = timeSheetsArray.filter(
          (timeSheet) => {
            const isInFragment =
              parseISO(timeSheet.date) >
                sub(currentFragmentTime, { days: 1 }) &&
              parseISO(timeSheet.date) < currentFragmentTime;
            return isInFragment;
          }
        );

        const name = format(sub(currentFragmentTime, { days: 1 }), 'd');

        const sumWorkedHours = timeSheetsInFragment.reduce(
          (acc, workedHours) => acc + workedHours.value,
          0
        );

        return {
          name,
          workedHours: sumWorkedHours
        };
      });

      break;
    }

    case GRAPH_PERIOD.THIS_YEAR: {
      const startYear = startOfYear(new Date());

      series = thisYearFragments.map((fragment) => {
        const currentFragmentTime = add(startYear, fragment);

        const timeSheetsInFragment = timeSheetsArray.filter(
          (timeSheet) => {
            const isInFragment =
              parseISO(timeSheet.date) >
                sub(currentFragmentTime, { months: 1 }) &&
              parseISO(timeSheet.date) < currentFragmentTime;
            return isInFragment;
          }
        );

        const name = format(
          sub(currentFragmentTime, { months: 1 }),
          'LLL'
        );

        const sumWorkedHours = timeSheetsInFragment.reduce(
          (acc, workedHours) => acc + workedHours.value,
          0
        );

        return {
          name,
          workedHours: sumWorkedHours
        };
      });

      break;
    }

    case GRAPH_PERIOD.ALL_TIME: {
      const sortedCandidatesByTime = timeSheetsArray.sort((a, b) =>
        a.date > b.date ? 1 : -1
      );

      const startYear = startOfYear(
        parseISO(sortedCandidatesByTime[0].date)
      );

      const lastYear = startOfYear(
        parseISO(
          sortedCandidatesByTime[sortedCandidatesByTime.length - 1].date
        )
      );

      const yearDiff =
        Math.abs(differenceInYears(lastYear, startYear)) + 1;

      if (yearDiff > 3) {
        series = Array.from({ length: yearDiff }, (_, i) => {
          const currentYear = add(startYear, { years: i });

          const timeSheetsInYear = timeSheetsArray.filter((timeSheet) => {
            const isInYear =
              parseISO(timeSheet.date) > currentYear &&
              parseISO(timeSheet.date) < add(currentYear, { years: 1 });
            return isInYear;
          });

          const name = format(currentYear, 'yyyy');

          const sumWorkedHours = timeSheetsInYear.reduce(
            (acc, workedHours) => acc + workedHours.value,
            0
          );

          return {
            name,
            workedHours: sumWorkedHours
          };
        });
      } else {
        const quarterDiff = yearDiff * 4;

        series = Array.from({ length: quarterDiff }, (_, i) => {
          const currentQuarter = add(startYear, { months: i * 3 });

          const timeSheetsInQuarter = timeSheetsArray.filter(
            (timeSheet) => {
              const isInQuarter =
                parseISO(timeSheet.date) > currentQuarter &&
                parseISO(timeSheet.date) <
                  add(currentQuarter, { months: 3 });
              return isInQuarter;
            }
          );

          const name = format(currentQuarter, 'yyyy QQQ');

          const sumWorkedHours = timeSheetsInQuarter.reduce(
            (acc, workedHours) => acc + workedHours.value,
            0
          );

          return {
            name,
            workedHours: sumWorkedHours
          };
        });
      }

      break;
    }

    default:
      series = [];
  }

  return {
    series
  };
};
