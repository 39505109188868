import { useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import {
  Box,
  CircularProgress,
  IconButton,
  Typography
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import './styles.scss';
import { createPortal } from 'react-dom';
import { ACTIVE_TAB } from './constants';
import InterviewDetailsTab from './components/InterviewDetailsTab';
import AISummaryTab from './components/AISummaryTab';
import NextStepsTab from './components/NextStepsTab';
import CandidateProfileTab from './components/CandidateProfileTab';
import { useGetSingleInterviewQuery } from '../../../queries/Interview';
import { useGetCandidateProfileTemplateDetailQuery } from '../../../queries/Candidate';
import config from '../../../config';
import { INTERVIEW_STATUSES } from '../../../constants/common';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const ModalWrapper = ({ children }) =>
  createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="interview-modal">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-0 content">
        {children}
      </motion.div>
    </motion.div>,
    document.body
  );

const InterviewModal = ({
  onClose,
  interviewId,
  openCampaignDetails,
  updateInterviewStatus
}) => {
  const [activeTab, setActiveTab] = useState(ACTIVE_TAB.INTERVIEW_DETAILS);
  const { data: interviewData, isLoading } = useGetSingleInterviewQuery({
    interview_id: interviewId
  });

  const profileTemplateDetailRequest =
    useGetCandidateProfileTemplateDetailQuery(
      {
        candidate_profile_status_id:
          interviewData?.candidate_profile_status_id,
        template_id: 1
      },
      {
        enabled:
          Boolean(interviewData?.candidate_profile_status_id) &&
          interviewData?.profile_status ===
            config.candidate.profileStatus.HIRED
      }
    );

  const { requirements } = profileTemplateDetailRequest?.data || [];

  return (
    <ModalWrapper>
      {!isLoading ? (
        <>
          <Box
            className="p-2 mb-1 header d-flex align-items-center"
            height="54px">
            <Box>
              {interviewData && (
                <>
                  <Typography color="#4d565c">
                    <b>{interviewData.full_name}</b> -{' '}
                    {interviewData.campaign_name}
                  </Typography>
                </>
              )}
            </Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}>
              <CloseRoundedIcon sx={{ fill: '#509ec6' }} />
            </IconButton>
          </Box>
          <div className="gap-2 tabs d-flex">
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.INTERVIEW_DETAILS
              })}
              onClick={(e) => {
                e.stopPropagation();
                setActiveTab(ACTIVE_TAB.INTERVIEW_DETAILS);
              }}>
              <span>Interview Details</span>
            </div>
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.AI_SUMMARY
              })}
              onClick={(e) => {
                e.stopPropagation();
                setActiveTab(ACTIVE_TAB.AI_SUMMARY);
              }}>
              <span>AI Summary</span>
            </div>
            {interviewData?.status === INTERVIEW_STATUSES.VOTED_UP && (
              <PermissionChecker
                permissions={['dashboard.interview.write']}>
                <div
                  className={classNames('tab pointer', {
                    tab__active: activeTab === ACTIVE_TAB.NEXT_STEPS
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveTab(ACTIVE_TAB.NEXT_STEPS);
                  }}>
                  <span>Next Steps</span>
                </div>
              </PermissionChecker>
            )}
            <div
              className={classNames('tab pointer', {
                tab__active: activeTab === ACTIVE_TAB.CANDIDATE_PROFILE
              })}
              onClick={(e) => {
                e.stopPropagation();
                setActiveTab(ACTIVE_TAB.CANDIDATE_PROFILE);
              }}>
              <span>Candidate Profile</span>
            </div>
          </div>
          <hr />
          <div className="tabs-content h-[480px]">
            {activeTab === ACTIVE_TAB.INTERVIEW_DETAILS && (
              <InterviewDetailsTab
                interviewData={interviewData}
                requirements={requirements}
                updateInterviewStatus={updateInterviewStatus}
                onAIReadMoreClick={() =>
                  setActiveTab(ACTIVE_TAB.AI_SUMMARY)
                }
              />
            )}
            {activeTab === ACTIVE_TAB.AI_SUMMARY && (
              <AISummaryTab
                interviewSummary={interviewData.summary}
                candidateSentimentSummary={
                  interviewData.candidate_sentiment_summary
                }
                candidateSentiment={interviewData.sentiment}
              />
            )}
            {activeTab === ACTIVE_TAB.NEXT_STEPS && (
              <NextStepsTab
                interviewData={interviewData}
                openCampaignDetails={openCampaignDetails}
                requirements={requirements}
              />
            )}
            {activeTab === ACTIVE_TAB.CANDIDATE_PROFILE && (
              <CandidateProfileTab interviewData={interviewData} />
            )}
          </div>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="1rem"
          minHeight="15rem">
          <CircularProgress />
        </Box>
      )}
    </ModalWrapper>
  );
};

export default InterviewModal;
