import { motion } from 'framer-motion';
import _remove from 'lodash/remove';
import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { sidebarContentVariants } from '../../../utils/animation';
import { logError } from '../../../utils/logging';
import Button from '../../shared/Button/Button';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../../shared/CustomScrollBar/CustomScrollBar';
import { CampaignCard } from '../CampaignCard/campaign-card';
import CampaignListFilter from './CampaignListFilter';

import PermissionChecker from '@src/components/shared/PermissionChecker';
import CampaignCreationModal from '../CampaignCreationModal';
import { CAMPAIGN_FILTER_TYPE } from './CampaignListFilter';

import './CampaignList.css';

const SEEN_CAMPAIGN_KEY = 'seenCampaigns';

const CampaignList = ({
  setSelectedCampaign,
  selectedCampaign,
  campaigns
}) => {
  const [isCampaignCreationModalOpened, setIsCampaignCreationModalOpened] =
    useState(false);

  const hasShownPauseNotice = useRef(false);

  const [filtererCampaignType, setFiltererCampaignType] = useState(
    CAMPAIGN_FILTER_TYPE.ALL
  );

  useEffect(() => {
    if (hasShownPauseNotice.current) return;

    const seenCampaignNotificationStorage =
      localStorage.getItem(SEEN_CAMPAIGN_KEY);

    const seenCampaignNotifications = seenCampaignNotificationStorage
      ? seenCampaignNotificationStorage.split(',')
      : [];

    if (campaigns?.length > 0) {
      campaigns.forEach((campaign) => {
        if (campaign.status === 0) {
          if (
            seenCampaignNotifications.every((campaignId) => {
              return String(campaignId) !== String(campaign.campaign_id);
            })
          ) {
            logError(
              `Your ${campaign.campaign_name} campaign has been paused. Please contact your Trusted Dev concierge if you need assistance.`
            );
            seenCampaignNotifications.push(campaign.campaign_id);
          }
        } else {
          _remove(seenCampaignNotifications, String(campaign.campaign_id));
        }
      });

      localStorage.setItem(
        SEEN_CAMPAIGN_KEY,
        seenCampaignNotifications.join(',')
      );

      hasShownPauseNotice.current = true;
    }
  }, [campaigns]);

  const handleSelectCampaign = (campaign) => {
    if (selectedCampaign?.id === campaign.id) {
      setSelectedCampaign(null);

      return;
    }

    setSelectedCampaign(campaign);
  };

  const filteredCampaigns = (() => {
    switch (filtererCampaignType) {
      case CAMPAIGN_FILTER_TYPE.ALL:
        return campaigns;

      case CAMPAIGN_FILTER_TYPE.ACTIVE:
        return campaigns.filter(
          (campaign) => campaign.is_active && !campaign.is_archived
        );

      case CAMPAIGN_FILTER_TYPE.PAUSED:
        return campaigns.filter((campaign) => !campaign.is_active);

      case CAMPAIGN_FILTER_TYPE.ARCHIVED:
        return campaigns.filter((campaign) => campaign.is_archived);

      case CAMPAIGN_FILTER_TYPE.NEED_ATTENTION:
        return campaigns.filter(
          (campaign) =>
            !campaign.deposit_paid || campaign.has_pending_candidates
        );

      default:
        break;
    }
  })();

  return (
    <motion.div
      layout
      variants={sidebarContentVariants}
      initial={'hidden'}
      animate={'show'}
      exit={'hidden'}
      className="campaign-list">
      {isCampaignCreationModalOpened && (
        <CampaignCreationModal
          isOpen={isCampaignCreationModalOpened}
          onClose={() => setIsCampaignCreationModalOpened(false)}
        />
      )}
      <PermissionChecker permissions={['dashboard.campaign.write']}>
        <div className="request-campaign flex gap-2 h-16 px-3">
          <CampaignListFilter onChange={setFiltererCampaignType} />
          <Button
            onClick={() => setIsCampaignCreationModalOpened(true)}
            className="h-8 p-0 min-h-0 flex-shrink-0">
            <span className="font-bold text-sm mx-1">CREATE CAMPAIGN</span>
          </Button>
        </div>
      </PermissionChecker>
      <Scrollbars
        id="campaignList"
        className="content"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}>
        <div className="wrapper">
          {filteredCampaigns?.map((campaign) => (
            <CampaignCard
              key={campaign.id}
              campaign={campaign}
              isSelected={campaign === selectedCampaign}
              selectCampaign={handleSelectCampaign}
            />
          ))}
        </div>
      </Scrollbars>
    </motion.div>
  );
};

export default CampaignList;
