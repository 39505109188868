import './CandidatePreviewCard.css';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';
import { ReactComponent as ViewResumeIcon } from '../../../assets/images/view-resume-icon.svg';
import { ReactComponent as HeartIcon } from '../../../assets/images/heart-icon.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/chevron-icon.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/star-icon.svg';
import { ReactComponent as GithubIcon } from '../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../assets/images/portfolio-icon.svg';
import { ReactComponent as BriefCaseIcon } from '../../../assets/images/briefcase-icon.svg';
import { ReactComponent as XIcon } from '../../../assets/images/x-icon.svg';

import { constructLocationString, linkSocialIcon } from '../helper';
import Button from './../../shared/Button/Button';
import { format } from 'date-fns';
import config from '../../../config';

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 0.6 },
  exit: { opacity: 0, transition: { delay: 0 } },
  transition: { type: 'just' }
};

const candidateCardAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0 } },
  transition: { type: 'just' }
};

const CandidatePreviewCard = ({ data, onClose, onFavorite }) => {
  const {
    candidate_profile_status_id,
    motto,
    resume_highlights,
    hired_datetime,
    is_favourite,
    profile_status,
    companies
  } = data || {};

  const closeCardIfNotTextSelection = () => {
    if (!window.getSelection().toString().length) {
      onClose();
    }
  };

  return (
    <motion.div
      layoutId={`card-${candidate_profile_status_id}`}
      className="flex fixed top-0 w-[100vw] h-[100vh] justify-center items-center z-2"
      onClick={closeCardIfNotTextSelection}
      {...candidateCardAnimation}>
      <motion.div className="d-flex flex-column candidate-preview-card pointer no-tap-highlight w-[950px] h-[500px] p-0 bg-white">
        <motion.div className="flex flex-1 h-full">
          <div className="flex min-w-[250px] relative">
            <XIcon
              className="pointer absolute z-10 left-2 top-2"
              onClick={onClose}
            />
            <img
              src={data?.avatar}
              className="absolute top-0 left-0 object-cover w-full h-full"
            />
            <div className="absolute bottom-0 left-0 w-full p-2 text-white z-1 bg-gradient-to-t from-black/55">
              <p className="text-[21px]">
                {data?.first_name + '-' + data?.last_name}
              </p>
              <p className="text-[14px]">{data?.tech_stack}</p>
              <p className="text-[12px] mt-2">
                {constructLocationString(
                  data?.city,
                  data?.state,
                  data?.country
                )}
              </p>
            </div>
          </div>
          <div className="p-4 pr-3 flex-1 flex flex-col overflow-y-scroll">
            <div className="flex items-center gap-8 border-b mb-4 border-[#b7d2dd] pb-3">
              {hired_datetime &&
                profile_status ===
                  config.candidate.profileStatus.HIRED && (
                  <div className="text-[11px] flex flex-col items-center">
                    <BriefCaseIcon className="fill-td-primary" />
                    <p>{format(new Date(hired_datetime), 'MM/dd/yyyy')}</p>
                  </div>
                )}
              <div className="flex items-center justify-center gap-2 text-[11px]">
                <div className="flex items-center mb-1">
                  <StarIcon
                    className={`star-icon${
                      data?.culture_fit >= 1 ? ' active' : ''
                    }`}
                  />
                  <StarIcon
                    className={`star-icon${
                      data?.culture_fit >= 2 ? ' active' : ''
                    }`}
                  />
                  <StarIcon
                    className={`star-icon${
                      data?.culture_fit >= 3 ? ' active' : ''
                    }`}
                  />
                </div>
                <span className="font-bold">Culture Fit</span>
              </div>
              <div className="flex items-center justify-center gap-1 text-[11px]">
                <span className="font-bold">Experience:</span>
                <span>{`${data?.experience} ${
                  data?.experience > 0 ? 'Years' : 'Year'
                }`}</span>
              </div>
              <div className="flex items-center gap-2 text-[11px]">
                <ChevronIcon />
                <span className="font-bold">Tech Assessment</span>
              </div>
              <div className="flex justify-end gap-4 ml-auto">
                {linkSocialIcon(data?.github_url, GithubIcon, 'Github')}
                {linkSocialIcon(
                  data?.linkedin_url,
                  LinkedinIcon,
                  'Linkedin'
                )}
                {linkSocialIcon(
                  data?.portfolio_url,
                  PortfolioIcon,
                  'Portfolio'
                )}
              </div>
            </div>
            <div className="flex h-full gap-9">
              <div className="flex flex-col w-full h-full flex-1">
                <div className="relative">
                  <p className="text-[#526c80] font-semibold mb-2">
                    Education
                  </p>
                  <div className="flex flex-col gap-1">
                    {!!data?.educations?.length &&
                      data.educations.map((education, index) => (
                        <div key={index} className="flex flex-col">
                          <span className="text-[13px] degree">
                            {education?.specialization}
                          </span>
                          <span className="font-semibold institution">
                            {education?.university +
                              ', ' +
                              education?.course}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                {Boolean(companies?.length) && (
                  <div className="mt-4">
                    <p className="text-[#526c80] font-semibold mb-2">
                      Worked For
                    </p>
                    <div>
                      <div className="flex gap-4">
                        {companies.map(
                          ({ company_logo_url, company_name }) => (
                            <div
                              className="max-h-[35px]"
                              key={company_name}>
                              <img
                                src={company_logo_url}
                                height="35"
                                className="max-h-[35px]"
                                alt={company_name}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {Boolean(resume_highlights) && (
                  <div>
                    <p className="text-[#526c80] font-semibold mb-2 mt-4">
                      Resume Highlights
                    </p>
                    <p className="text-[14px] line-clamp-5">
                      {resume_highlights}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex-1 flex flex-col justify-between flex-shrink-0">
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-[#526c80] font-semibold mb-2">
                      Skills
                    </p>
                    {!!data?.skills?.length && (
                      <ul className="ml-4 list-disc">
                        {data?.skills?.map(({ id, skill_name }) => (
                          <li key={id}> {skill_name}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {Boolean(motto) && (
                    <div className="text-[#509ec6] font-semibold mt-4">
                      “{motto}”
                    </div>
                  )}
                  <div>
                    <p className="text-[#526c80] font-semibold mb-2">
                      Hobbies
                    </p>
                    <ul className="ml-4 list-disc">
                      {data?.hobbies?.map(({ id, hobby_name }) => (
                        <li key={id}>{hobby_name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex justify-between gap-3">
                  <a
                    href={data?.resume}
                    className="candidate-no-underline align-self-center"
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    <Button
                      icon={ViewResumeIcon}
                      className="h-8 text-xs flex-1"
                      title="VIEW FULL RESUME"
                      onClick={() => {}}
                    />
                  </a>
                  <Button
                    className="h-8 flex-1 text-xs"
                    icon={() => <HeartIcon className="min-w-[16px]" />}
                    title={is_favourite ? 'UNFAVORITE' : 'FAVORITE'}
                    onClick={onFavorite}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

CandidatePreviewCard.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired
};

const CandidatePreviewCardWrapper = (props) => {
  return createPortal(
    <div className="flex items-center justify-center candidate-preview-card-container">
      <motion.button
        onClick={props.onClose}
        className="candidate-backdrop z-1"
        {...animation}
      />
      <CandidatePreviewCard {...props} />
    </div>,
    document.body
  );
};

export default CandidatePreviewCardWrapper;
