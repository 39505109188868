import { ReactComponent as FilterIcon } from '@src/assets/images/filter-icon.svg';
import { useDispatch } from 'react-redux';
import { toggleMobileFilters } from '@src/store/slices/Dashboard';

const HomeMobileActivityFeedFilters = () => {
  const dispatch = useDispatch();

  const handleOpenFilterModal = () => {
    dispatch(toggleMobileFilters());
  };

  return (
    <div className="flex items-center gap-4">
      <FilterIcon
        onClick={handleOpenFilterModal}
        className="size-[26px] cursor-pointer fill-td-blissful-blue"
      />
    </div>
  );
};

export default HomeMobileActivityFeedFilters;
