import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Grid, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRef, useState } from 'react';

export const CAMPAIGN_FILTER_TYPE = {
  ALL: 'All',
  ACTIVE: 'Active',
  PAUSED: 'Paused',
  NEED_ATTENTION: 'Need Attention',
  ARCHIVED: 'Archived'
};

const CampaignListFilter = ({ onChange }) => {
  const buttonRef = useRef();
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    CAMPAIGN_FILTER_TYPE.ALL
  );

  const handleClick = () => {
    setAnchorElement(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div className="relative w-full">
      <Grid
        container
        ref={buttonRef}
        alignItems="center"
        gap="0.25rem"
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          background: '#fff',
          padding: '0.15rem 0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #509ec6',
          justifyContent: 'space-between'
        }}>
        <Grid item>
          <Typography color="#265d84">{selectedOption}</Typography>
        </Grid>
        <Grid item>
          <KeyboardArrowDownRoundedIcon
            style={{
              width: '1rem',
              height: '1.5rem',
              fill: '#509ec6',
              transform: anchorElement ? 'rotate(180deg)' : 'rotate(0)'
            }}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleClose}>
        {Object.entries(CAMPAIGN_FILTER_TYPE).map(([key, value]) => (
          <MenuItem
            key={key}
            onClick={() => {
              setSelectedOption(value);
              onChange(value);
              handleClose();
            }}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CampaignListFilter;
