import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { setActivityFeedFilter } from '../../../store/slices/Dashboard';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './DateRangePicker.module.scss';
import dayjs from 'dayjs';
import { cn } from '@src/utils';

const DateRangePicker = ({ className, wrapperClassName }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    const startString = start
      ? dayjs(start).startOf('day').format('YYYY-MM-DD')
      : null;

    const endString = end
      ? dayjs(end).endOf('day').format('YYYY-MM-DD')
      : null;

    dispatch(
      setActivityFeedFilter({
        dateRange: {
          from: startString,
          to: endString
        }
      })
    );
  };

  return (
    <div className={cn(wrapperClassName, styles.datePicker)}>
      <ReactDatePicker
        className={cn(className, 'text-sm font-semibold')}
        placeholderText="Select Date Range"
        isClearable
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        enableTabLoop={false}
        dateFormat={'dd.MM.YY'}
      />
    </div>
  );
};

DateRangePicker.prototype = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string
};

export default DateRangePicker;
