import './NewMatchesCard.css';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import { ReactComponent as ChevronIcon } from '../../../assets/images/chevron-icon.svg';
import { ReactComponent as ViewResumeIcon } from '../../../assets/images/view-resume-icon.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/star-icon.svg';
import {
  constructLocationString,
  linkSocialIcon
} from '../../candidates/helper';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import Button from './../../shared/Button/Button';
import { ReactComponent as GithubIcon } from '../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../assets/images/portfolio-icon.svg';

const NewMatchesCard = ({
  data,
  initial,
  animate,
  transition,
  setExitX,
  setIndex,
  exitX,
  index,
  isLast,
  onDislike,
  onLike
}) => {
  const { motto, resume_highlights } = data || {};
  const x = useMotionValue(0);
  const rotateX = useTransform(x, [-300, 0, 300], [-5, 0, 5], {
    clamp: false
  });

  const handleDislike = () => {
    setExitX(-1000);
    if (!isLast) {
      setIndex(index + 1);
    }
    onDislike(data);
  };

  const handleLike = () => {
    setExitX(1000);
    if (!isLast) {
      setIndex(index + 1);
    }
    onLike(data);
  };

  function handleDragEnd(_, info) {
    if (info.velocity.x < -500) {
      handleDislike();
    }
    if (info.velocity.x > 500) {
      handleLike();
    }
  }

  return (
    <motion.div
      className="new-matches-card no-scrollbar z-[1] max-w-[420px] w-[calc(100vw-1rem)] h-[85vh] mx-2 bg-white rounded-md overflow-auto absolute"
      style={{
        x: x,
        rotate: rotateX,
        cursor: 'grab'
      }}
      dragDirectionLock
      drag="x"
      whileTap={{ cursor: 'grabbing' }}
      dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
      onDragEnd={handleDragEnd}
      initial={initial}
      animate={animate}
      transition={transition}
      exit={{
        x: exitX,
        opacity: 0,
        transition: { duration: 0.6 }
      }}
      dragTransition={{ bounceStiffness: 600 }}>
      <div className="flex flex-col h-full min-h-0">
        <div className="relative transition-all h-[330px]">
          <img
            draggable={false}
            src={data?.avatar}
            className="top-0 left-0 object-cover w-full h-full"
          />
          <div className="absolute bottom-0 left-0 w-full p-2 text-white z-1 bg-gradient-to-t from-black/55">
            <p className="text-[21px]">
              {data?.first_name + '-' + data?.last_name}
            </p>
            <p className="text-[14px]">{data?.tech_stack}</p>
            <p className="text-[12px] mt-2">
              {constructLocationString(
                data?.city,
                data?.state,
                data?.country
              )}
            </p>
          </div>
          <div className="absolute top-0 left-0 flex items-center justify-between w-full h-full z-2">
            <motion.div
              onClick={handleDislike}
              className="ml-2 rounded-full border-2 border-white w-[42px] h-[42px] flex items-center justify-center">
              <ThumbDownRoundedIcon className="fill-white" />
            </motion.div>
            <motion.div
              onClick={handleLike}
              className="mr-2 rounded-full border-2 border-white w-[42px] h-[42px] flex items-center justify-center">
              <ThumbUpRoundedIcon className="fill-white" />
            </motion.div>
          </div>
        </div>
        <div className="px-4 pt-4">
          <div className="flex items-center justify-between border-b border-[#b7d2dd] pb-2 mb-4">
            <div>
              <div className="flex justify-center">
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 1 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 2 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 3 ? ' active' : ''
                  }`}
                />
              </div>
              <p className="text=[#526c80] font-semibold">Culture Fit</p>
            </div>
            <div className="text-center">
              <p className="text=[#526c80] font-semibold">Experience</p>
              <span>{`${data?.experience} ${
                data?.experience > 0 ? 'Years' : 'Year'
              }`}</span>
            </div>
            <div className="flex items-center gap-2">
              <ChevronIcon />
              <span className="text=[#526c80] font-semibold">
                Tech
                <br />
                Assessment
              </span>
            </div>
          </div>
          <div>
            <div className="relative">
              <div className="absolute top-0 right-0 flex justify-end w-full gap-4">
                {linkSocialIcon(data?.github_url, GithubIcon, 'Github')}
                {linkSocialIcon(
                  data?.linkedin_url,
                  LinkedinIcon,
                  'Linkedin'
                )}
                {linkSocialIcon(
                  data?.portfolio_url,
                  PortfolioIcon,
                  'Portfolio'
                )}
              </div>
            </div>
            <div>
              <p className="text-[#526c80] font-semibold mb-2">
                Education
              </p>
              <div className="flex flex-col gap-1">
                {!!data?.educations?.length &&
                  data.educations.map((education, index) => (
                    <div key={index} className="flex flex-col">
                      <span className="text-[13px] degree">
                        {education?.specialization}
                      </span>
                      <span className="font-semibold institution">
                        {education?.university + ', ' + education?.course}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {Boolean(resume_highlights) && (
            <div>
              <p className="text-[#526c80] font-semibold mb-2 mt-4">
                Resume Highlights
              </p>
              <p className="text-[14px] line-clamp-5">
                {resume_highlights}
              </p>
            </div>
          )}
          <div className="my-4">
            <p className="text-[#526c80] font-semibold mb-2">Worked For</p>
            <div>
              <div className="flex gap-4">
                <div className="max-h-[35px]">
                  <img
                    src="/brands/nike.png"
                    height="35"
                    className="max-h-[35px]"
                    alt="Nike"
                    draggable={false}
                  />
                </div>
                <div className="max-h-[35px]">
                  <img
                    src="/brands/nbc.png"
                    height="35"
                    className="max-h-[35px]"
                    alt="NBC"
                    draggable={false}
                  />
                </div>
                <div className="max-h-[35px]">
                  <img
                    src="/brands/tesla.png"
                    className="max-h-[35px]"
                    alt="Tesla"
                    draggable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {Boolean(motto) && (
              <div className="text-[#509ec6] font-semibold">“{motto}”</div>
            )}
            <div>
              <p className="text-[#526c80] font-semibold mb-2">Skills</p>
              {!!data?.skills?.length && (
                <ul className="ml-4 list-disc">
                  {data?.skills?.map(({ id, skill_name }) => (
                    <li key={id}> {skill_name}</li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <p className="text-[#526c80] font-semibold mb-2">Hobbies</p>
              <ul className="ml-4 list-disc">
                {data?.hobbies?.map(({ id, hobby_name }) => (
                  <li key={id}>{hobby_name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="my-8 education-row">
            <a
              href={data?.resume}
              className="candidate-no-underline align-self-center"
              target="_blank"
              rel="noopener noreferrer nofollow">
              <Button
                className="view-resume-btn"
                icon={ViewResumeIcon}
                title="VIEW FULL RESUME"
                onClick={() => {}}
              />
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewMatchesCard;
