import {
  Tooltip as OriginalTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@src/components/ui/tooltip';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '@src/utils';

const Tooltip = ({ children, content, contentClassName }) => {
  return (
    <TooltipProvider>
      <OriginalTooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipPrimitive.Portal>
          <TooltipContent className={cn(contentClassName, 'bg-white')}>
            {content}
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </OriginalTooltip>
    </TooltipProvider>
  );
};

export default Tooltip;
