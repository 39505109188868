import './UpcomingInterviewList.scss';
import { motion } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars-2';
import UpcomingInterviewListItem from '../UpcomingInterviewListItem/UpcomingInterviewListItem';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../../shared/CustomScrollBar/CustomScrollBar';
import {
  sidebarContentVariants,
  tabContentMobileVariants
} from '../../../utils/animation';
import { useIfMobile } from '../../../utils/responsive';
import { useGetInterviewsQuery } from '../../../queries/Interview';
import InterviewDateFilter from '../InterviewDateFilter';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { INTERVIEW_DATE_FILTER_OPTIONS } from '../../../constants/common';
import SortIcon from '@mui/icons-material/Sort';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DatePicker from '../../shared/DatePicker';
import { getStartAndEndTimeOfDay } from '../../../utils/dateTime';

const UpcomingInterviewList = () => {
  const [filters, setFilters] = useState(
    INTERVIEW_DATE_FILTER_OPTIONS.TODAY.getDateRange()
  );
  const isMobile = useIfMobile();
  const { mutateAsync: getInterviews } = useGetInterviewsQuery();
  const [interviews, setInterviews] = useState();

  const [isToggled, setToggled] = useState(true);

  const [customDate, setCustomDate] = useState(new Date());
  const [selectedDate, handleDateChange] = useState(null);
  const [showNewHeader, setShowNewHeader] = useState(false);

  const handleBackToOldHeader = () => {
    setShowNewHeader(false);
    handleDateChange(null);
    setCustomDate(new Date());
    setFilters(INTERVIEW_DATE_FILTER_OPTIONS.TODAY.getDateRange());
  };

  const handleToggle = () => {
    setToggled(!isToggled);

    if (isToggled) {
      handleBackToOldHeader();
    }
  };

  useEffect(() => {
    const fetchInterviews = async () => {
      setInterviews([]);
      const fetchedInterviews = await getInterviews(filters);
      setInterviews(fetchedInterviews?.client_interviews);
    };
    fetchInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const client_interviews =
    interviews?.map((interview) => ({
      ...interview,
      firstName: interview.first_name,
      lastName: interview.last_name,
      description: interview.interview_description,
      date: new Date(interview.interview_date)
    })) || [];
  return (
    <motion.div
      variants={
        isMobile ? tabContentMobileVariants : sidebarContentVariants
      }
      initial={'hidden'}
      animate={'show'}
      exit={'hidden'}
      className="upcoming-interview-list">
      {showNewHeader ? (
        <div className="header new-header">
          <ArrowBackIosNewIcon
            onClick={handleBackToOldHeader}
            sx={{
              width: '1.25rem',
              height: '1.25rem',
              zIndex: '1',
              cursor: 'pointer'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '0'
            }}>
            <Typography color="#fff" fontSize={18} fontWeight="500">
              {`Interview - ${selectedDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })}`}
            </Typography>
          </Box>
        </div>
      ) : (
        <div className="header new-header">
          <Box display="flex" gap={1} zIndex={1}>
            <CalendarTodayIcon
              sx={{
                width: '1.25rem',
                height: '1.25rem'
              }}
            />

            <ToggleSwitch
              active={isToggled}
              onToggle={handleToggle}
              className="custom-toggle-switch"
              label="Toggle Switch Label"
              hasNoDisabledState
            />

            <SortIcon
              sx={{
                marginRight: 'auto',
                width: '1.25rem',
                height: '1.25rem'
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '0'
            }}>
            <Typography color="#fff">Interviews</Typography>
          </Box>

          {isToggled && (
            <Box marginLeft="auto" zIndex={1}>
              <InterviewDateFilter onDateRangeChange={setFilters} />
            </Box>
          )}
        </div>
      )}

      <Scrollbars
        id="upcomingInterviewList"
        className="content"
        renderThumbVertical={() =>
          ScrollThumbVertical({ color: '#134067' })
        }
        renderTrackVertical={() =>
          ScrollTrackVertical({ color: '#1E517E', width: '0' })
        }
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
        hideTracksWhenNotNeeded={isMobile}>
        {isToggled && (
          <div className="m-3 mr-4 flex flex-col gap-3">
            {client_interviews?.map((interview) => (
              <UpcomingInterviewListItem
                key={interview.id}
                data={interview}
              />
            ))}
          </div>
        )}

        {!isToggled && (
          <div className="centered-datepicker">
            <DatePicker
              selected={customDate}
              inline
              onChange={(date) => {
                setCustomDate(date);
                const { startDate, endDate } =
                  getStartAndEndTimeOfDay(date);
                setFilters({ startDate, endDate });
                handleToggle(false);
                handleDateChange(date);
                setShowNewHeader(true);
              }}
            />
          </div>
        )}
      </Scrollbars>
    </motion.div>
  );
};

export default UpcomingInterviewList;
