import Scrollbars from 'react-custom-scrollbars-2';
import LoadingOverlay from '@src/components/budget-management/LoadingOverlay/LoadingOverlay';
import {
  ScrollThumbVertical,
  ScrollTrackVertical
} from '../shared/CustomScrollBar/CustomScrollBar';
import { useSelector } from 'react-redux';
import { useGetCampaignActivityList } from '@src/queries/Campaign';
import dayjs from 'dayjs';
import { selectActivityFeedFilter } from '@src/store/selectors/Dashboard';

const MobileHistoryList = () => {
  const { data, isLoading } = useGetCampaignActivityList();

  const activityFeedFilter = useSelector(selectActivityFeedFilter);

  const dateRange = activityFeedFilter.dateRange;
  const campaignId = activityFeedFilter.campaignId;

  const transformType = (type) => {
    const words = type.split('_');
    return words
      .map(
        (word) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  };

  const filteredList = (() => {
    if (!data) return [];

    let filteredList = [...data];

    if (dateRange.from && dateRange.to) {
      const startDate = dayjs(dateRange.from, 'YYYY-MM-DD');
      const endDate = dayjs(dateRange.to, 'YYYY-MM-DD');

      filteredList = filteredList.filter((activity) => {
        const createdDate = dayjs(activity.created_datetime);

        return (
          createdDate.isAfter(startDate) && createdDate.isBefore(endDate)
        );
      });
    }

    if (campaignId) {
      filteredList = filteredList.filter(
        (activity) => activity.campaign_id === campaignId
      );
    }

    return filteredList;
  })();

  return (
    <div className="h-full w-full flex flex-col bg-white">
      <Scrollbars
        className="-mb-[12px] relative"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}>
        <LoadingOverlay open={isLoading} />
        {filteredList.map((activity) => {
          const {
            id,
            campaign_name,
            activity_type,
            created_datetime,
            created_by_name
          } = activity;

          return (
            <div
              key={id}
              className="flex flex-col px-8 py-4 border-b border-td-big-sur">
              <p className="text-base font-semibold flex-[1.25] text-td-boyzone">
                {transformType(activity_type)}
              </p>
              <p className="w-fit text-sm mt-[14px] mb-4 text-td-submarine-grey font-semibold bg-[#edf3f6] p-1 px-2 rounded-2xl">
                {campaign_name}
              </p>
              <div className="flex justify-between items-center">
                <p className="flex-[0.5] text-sm text-td-submarine-grey">
                  {created_by_name}
                </p>
                <p className="text-sm flex-1 w-fit text-right text-[#88a6b8] font-semibold">
                  {dayjs(created_datetime).format('h:mm A MMM D, YYYY')}
                </p>
              </div>
            </div>
          );
        })}
      </Scrollbars>
    </div>
  );
};

export default MobileHistoryList;
