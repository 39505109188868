import { constructLocationString } from '../../../../candidates/helper';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/chevron-icon.svg';
import Avatar from '../../../../shared/Avatar/Avatar';
import './InterviewDetailsTab.scss';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import VideoPlayer from '../../../../shared/VideoPlayer';
import { format } from 'date-fns';
import classNames from 'classnames';
import { INTERVIEW_STATUSES } from '../../../../../constants/common';
import CandidateService from '../../../../../services/Candidate';
import config from '../../../../../config';
import { useUpdateClientInterviewStatusQuery } from '../../../../../queries/Interview';
import { useEffect, useState } from 'react';
import ComplianceRedirectionBar from './ComplianceRedirectionBar';
import TruncatedTypography from '../../../../shared/TruncatedTypography';
import PermissionChecker from '@src/components/shared/PermissionChecker';

const InterviewDetailsTab = ({
  interviewData = {},
  requirements = [],
  updateInterviewStatus,
  onAIReadMoreClick
}) => {
  const [interviewStatus, setInterviewStatus] = useState();

  const {
    full_name,
    tech_stack,
    city,
    state,
    country,
    assessment_score,
    avatar,
    interview_date,
    summary,
    candidate_profile_status_id,
    profile_status,
    interview_note,
    recording_link
  } = interviewData;

  const { mutateAsync: updateInterviewStatusInServer } =
    useUpdateClientInterviewStatusQuery();

  useEffect(() => {
    setInterviewStatus(interviewData?.status);
  }, [interviewData?.status]);

  const handleVote = (status) => {
    if (interviewStatus === status) {
      return;
    }

    setInterviewStatus(status);

    updateInterviewStatusInServer({
      interview_id: interviewData.id,
      status: status
    });
    updateInterviewStatus?.(status);
  };

  return (
    <Box className="interview-details-tab">
      <Box padding="1rem 2rem">
        <Grid container gap="1rem">
          <Grid item className="d-flex personal-info-row">
            <Box className="photo-info-container">
              <Box className="photo-container">
                {!!assessment_score && <ChevronIcon className="chevron" />}
                <Avatar
                  src={avatar}
                  alt={`${full_name}'s Profile`}
                  width={6}
                  height={6}
                />
              </Box>
            </Box>
            <Box className="d-flex flex-column justify-content-between">
              <Box className="d-flex flex-column">
                <Typography
                  className="d-inline-block align-self-start"
                  fontSize="18px"
                  fontWeight="700">
                  {full_name}
                </Typography>
                <Typography
                  className="d-inline-block align-self-start"
                  fontSize="16px"
                  fontWeight="700">
                  {tech_stack}
                </Typography>
              </Box>
              <Box className="d-flex flex-column">
                <span className="location d-inline-block align-self-start">
                  {constructLocationString(city, state, country)}
                </span>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item minWidth="280px">
            <Box marginBottom="1rem">
              <Typography as="p" textAlign="center">
                <Typography
                  as="span"
                  fontWeight="700"
                  paddingRight="0.5rem">
                  Interviewed:
                </Typography>
                <Typography as="span">
                  {interview_date
                    ? format(new Date(interview_date), 'MM/dd/yy h:mm aa')
                    : 'N/A'}
                </Typography>
              </Typography>
            </Box>
            <Grid container gap="1.5rem" justifyContent="center">
              <Grid
                item
                className={classNames('icon-container thumb-up', {
                  active: interviewStatus === INTERVIEW_STATUSES.VOTED_UP
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleVote(INTERVIEW_STATUSES.VOTED_UP);
                }}>
                <ThumbUpRoundedIcon className="styled-icon" />
              </Grid>
              <Grid
                item
                className={classNames('icon-container thumb-down', {
                  active: interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleVote(INTERVIEW_STATUSES.VOTED_DOWN);
                }}>
                <ThumbDownRoundedIcon className="styled-icon" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {interviewStatus === INTERVIEW_STATUSES.VOTED_UP &&
        profile_status !== config.candidate.profileStatus.HIRED && (
          <PermissionChecker permissions={['dashboard.interview.write']}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              gap="1rem"
              padding="0.75rem"
              bgcolor="#509ec6">
              <Grid item>
                <Typography color="white" fontWeight={600}>
                  {`You decided that ${full_name} is a good match for your team!`}
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    color: '#509ec6 !important',
                    bgcolor: 'white !important'
                  }}
                  onClick={() =>
                    CandidateService.updateCandidateProfileStatus(
                      candidate_profile_status_id,
                      config.candidate.profileStatus.HIRED
                    )
                  }>
                  HIRE CANDIDATE
                </Button>
              </Grid>
            </Grid>
          </PermissionChecker>
        )}
      {interviewStatus === INTERVIEW_STATUSES.VOTED_UP &&
        profile_status === config.candidate.profileStatus.HIRED && (
          <ComplianceRedirectionBar
            candidateProfileStatusId={candidate_profile_status_id}
            requirements={requirements}
          />
        )}
      <Grid container padding="0.5rem 2rem 0.35rem 2rem" gap="1rem">
        <Grid item width="50%">
          {recording_link && <VideoPlayer src={recording_link} />}
        </Grid>
        <Grid item width="50%">
          {summary && (
            <>
              <Typography
                color="#509ec6"
                fontWeight={600}
                fontSize={16}
                marginBottom="4px">
                AI Generated Interview Summary
              </Typography>
              <TruncatedTypography onReadMore={onAIReadMoreClick}>
                {summary}
              </TruncatedTypography>
            </>
          )}
        </Grid>
      </Grid>
      <Box padding="0.35rem 2rem 0 2rem">
        <Typography
          color="#509ec6"
          fontSize={14}
          fontWeight={600}
          marginBottom="4px">
          Interviewer Notes
        </Typography>
        <TextField
          multiline
          sx={{ width: '100%' }}
          rows={3}
          defaultValue={interview_note}
        />
      </Box>
    </Box>
  );
};

export default InterviewDetailsTab;
