import './NewMatchesCard.css';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import { ReactComponent as ChevronIcon } from '../../../assets/images/chevron-icon.svg';
import { ReactComponent as ViewResumeIcon } from '../../../assets/images/view-resume-icon.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/star-icon.svg';
import { ReactComponent as GithubIcon } from '../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../assets/images/portfolio-icon.svg';
import {
  constructLocationString,
  linkSocialIcon
} from '../../candidates/helper';
import Button from './../../shared/Button/Button';

const DesktopNewMatchCard = ({
  data,
  initial,
  animate,
  transition,
  setExitX,
  setExitY,
  setIndex,
  exitX,
  index,
  isLast,
  onDislike,
  onLike
}) => {
  const { motto, resume_highlights } = data || {};
  const x = useMotionValue(0);
  const rotateX = useTransform(x, [-300, 0, 300], [-5, 0, 5], {
    clamp: false
  });

  function handleDragEnd(_, info) {
    setExitY(info.offset.y);

    if (info.velocity.x < -500) {
      setExitX(-1000);
      if (!isLast) {
        setIndex(index + 1);
      }
      onDislike(data);
    }
    if (info.velocity.x > 500) {
      setExitX(1000);
      if (!isLast) {
        setIndex(index + 1);
      }
      onLike(data);
    }
  }

  return (
    <motion.div
      className="new-matches-card w-[930px] h-[450px] bg-white rounded-md overflow-hidden border-[#a2c1d7] border"
      style={{
        position: 'absolute',
        x,
        rotate: rotateX,
        cursor: 'grab'
      }}
      whileTap={{ cursor: 'grabbing' }}
      drag="x"
      dragDirectionLock
      dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
      onDragEnd={handleDragEnd}
      initial={initial}
      animate={animate}
      transition={transition}
      exit={{
        x: exitX,
        opacity: 0,
        transition: { duration: 0.6 }
      }}
      dragTransition={{ bounceStiffness: 600 }}>
      <motion.div className="flex w-full">
        <div className="flex w-[250px] h-[450px] relative">
          <img
            draggable={false}
            src={data?.avatar}
            className="absolute top-0 left-0 object-cover w-full h-full"
          />
          <div className="absolute bottom-0 left-0 w-full p-2 text-white z-1 bg-gradient-to-t from-black/55">
            <p className="text-[21px]">
              {data?.first_name + '-' + data?.last_name}
            </p>
            <p className="text-[14px]">{data?.tech_stack}</p>
            <p className="text-[12px] mt-2">
              {constructLocationString(
                data?.city,
                data?.state,
                data?.country
              )}
            </p>
          </div>
        </div>
        <div className="flex flex-col flex-1 h-auto m-4">
          <div className="flex items-center gap-6 border-b border-[#b7d2dd] pb-2">
            <div className="flex gap-2">
              <div className="flex items-center">
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 1 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 2 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 3 ? ' active' : ''
                  }`}
                />
              </div>
              <span>Culture Fit</span>
            </div>
            <div className="flex gap-1">
              <span className="font-semibold">Experience:</span>
              <span>{`${data?.experience} ${
                data?.experience > 0 ? 'Years' : 'Year'
              }`}</span>
            </div>
            <div className="flex items-center gap-2">
              <ChevronIcon />
              <span>Tech Assessment</span>
            </div>
            <div className="flex gap-4 ml-auto">
              {linkSocialIcon(data?.github_url, GithubIcon, 'Github')}
              {linkSocialIcon(
                data?.linkedin_url,
                LinkedinIcon,
                'Linkedin'
              )}
              {linkSocialIcon(
                data?.portfolio_url,
                PortfolioIcon,
                'Portfolio'
              )}
            </div>
          </div>
          <div className="flex h-full gap-2 my-4 mb">
            <div className="w-[50%] flex flex-col h-full gap-4">
              <div>
                <p className="text-[#526c80] font-semibold mb-2">
                  Education
                </p>
                <div className="flex flex-col gap-1">
                  {!!data?.educations?.length &&
                    data?.educations?.map((education, index) => (
                      <div className="flex flex-col" key={index}>
                        <span className="degree">
                          {education?.specialization}
                        </span>
                        <span className="font-semibold institution">
                          {education?.university +
                            ', ' +
                            education?.course}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <p className="text-[#526c80] font-semibold mb-2">
                  Worked For
                </p>
                <div>
                  <div className="flex gap-4">
                    <div className="max-h-[35px]">
                      <img
                        src="/brands/nike.png"
                        height="35"
                        className="max-h-[35px]"
                        alt="Nike"
                      />
                    </div>
                    <div className="max-h-[35px]">
                      <img
                        src="/brands/nbc.png"
                        height="35"
                        className="max-h-[35px]"
                        alt="NBC"
                      />
                    </div>
                    <div className="max-h-[35px]">
                      <img
                        src="/brands/tesla.png"
                        className="max-h-[35px]"
                        alt="Tesla"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {Boolean(resume_highlights) && (
                <div>
                  <p className="text-[#526c80] font-semibold mb-2 mt-4">
                    Resume Highlights
                  </p>
                  <p className="text-[14px] line-clamp-5">
                    {resume_highlights}
                  </p>
                </div>
              )}
            </div>
            <div className="w-[50%] flex flex-col h-full gap-2">
              <div className="flex flex-col gap-4">
                {Boolean(motto) && (
                  <div className="text-[#509ec6] font-semibold">
                    “{motto}”
                  </div>
                )}
                <div>
                  <p className="text-[#526c80] font-semibold mb-2">
                    Skills
                  </p>
                  {!!data?.skills?.length && (
                    <ul className="ml-4 list-disc">
                      {data?.skills?.map(({ id, skill_name }) => (
                        <li key={id}> {skill_name}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <div>
                  <p className="text-[#526c80] font-semibold mb-2">
                    Hobbies
                  </p>
                  <ul className="ml-4 list-disc">
                    {data?.hobbies?.map(({ id, hobby_name }) => (
                      <li key={id}>{hobby_name}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-auto">
                <a
                  href={data?.resume}
                  className="candidate-no-underline align-self-center"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  <Button
                    className="view-resume-btn"
                    icon={ViewResumeIcon}
                    title="VIEW FULL RESUME"
                    onClick={() => {}}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DesktopNewMatchCard;
