import DateRangePicker from '@src/components/activity-feed/DateRangePicker/DateRangePicker';
import Dropdown from '@src/components/shared/Dropdown';
import { setActivityFeedFilter } from '@src/store/slices/Dashboard';
import { useGetCampaignsQuery } from '@src/queries/Campaign';
import { useSelector, useDispatch } from 'react-redux';
import { selectActivityFeedFilter } from '@src/store/selectors/Dashboard';
import { useGetCandidatesQuery } from '@src/queries/Candidate';
import { getFullName } from '@src/utils/common';
import { ReactComponent as XIcon } from '@src/assets/images/x-icon.svg';
import { selectShowMobileFilters } from '@src/store/selectors/Dashboard';
import { toggleMobileFilters } from '@src/store/slices/Dashboard';
import { cn } from '@src/utils';

const ActivityMobileSearchModal = () => {
  const dispatch = useDispatch();

  const showMobileFilters = useSelector(selectShowMobileFilters);

  const { data: campaigns, isLoading: getCampaignLoading } =
    useGetCampaignsQuery();

  const { data: candidates, isLoading: getCandidateLoading } =
    useGetCandidatesQuery();

  const activityFeedFilter = useSelector(selectActivityFeedFilter);

  const campaignOptions = (() => {
    if (!campaigns || getCampaignLoading) return [];

    const options = campaigns.map((campaign) => ({
      campaignName: campaign.campaign_name,
      id: campaign.campaign_id
    }));

    return [{ campaignName: 'All Campaigns', id: null }, ...options];
  })();

  const candidateOptions = (() => {
    if (!candidates || getCandidateLoading) return [];

    const options = candidates.map((candidate) => ({
      name: getFullName(candidate.first_name, candidate.last_name),
      id: candidate.candidate_id
    }));

    return [{ name: 'All Candidates', id: null }, ...options];
  })();

  const handleCloseFilterModal = () => {
    dispatch(toggleMobileFilters());
  };

  return (
    <div
      className={cn(
        'absolute z-10 h-full w-full flex pt-16 justify-center backdrop-blur-[9px] bg-td-suez-canal/80 top-[42px] left-0',
        showMobileFilters
          ? 'opacity-100 transition-opacity'
          : 'opacity-0 transition-opacity'
      )}>
      <XIcon
        className="absolute top-4 right-4 cursor-pointer fill-white"
        onClick={handleCloseFilterModal}
      />
      <div className="flex flex-col items-center gap-3 w-full mx-6 relative">
        <Dropdown
          disabled={getCampaignLoading}
          options={campaignOptions}
          wrapperClassName="w-full max-w-96"
          onChange={(campaign) => {
            dispatch(
              setActivityFeedFilter({
                campaignId: campaign?.id
              })
            );
          }}
          selectedOption={campaignOptions.find(
            (campaign) => campaign.id === activityFeedFilter.campaignId
          )}
          labelKey="campaignName"
        />
        <Dropdown
          disabled={getCandidateLoading}
          options={candidateOptions}
          wrapperClassName="w-full max-w-96"
          onChange={(candidate) => {
            dispatch(
              setActivityFeedFilter({
                candidateId: candidate?.id
              })
            );
          }}
          selectedOption={candidateOptions.find(
            (candidate) => candidate.id === activityFeedFilter.candidateId
          )}
          labelKey="name"
        />
        <DateRangePicker
          wrapperClassName="w-full max-w-96 [&>div]:w-full"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ActivityMobileSearchModal;
