import './CandidatePreviewCardMobile.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Tippy from '@tippyjs/react';

import { ReactComponent as HeartIcon } from '../../../../../assets/images/heart-icon.svg';
import { ReactComponent as ViewResumeIcon } from '../../../../../assets/images/view-resume-icon.svg';
import { ReactComponent as ChevronIcon } from '../../../../../assets/images/chevron-icon.svg';
import { ReactComponent as StarIcon } from '../../../../../assets/images/star-icon.svg';
import { ReactComponent as GithubIcon } from '../../../../../assets/images/github-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../assets/images/linkedin-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../../../assets/images/portfolio-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/x-icon.svg';
import { ReactComponent as BriefCaseIcon } from '../../../../../assets/images/briefcase-icon.svg';
import { constructLocationString, linkSocialIcon } from '../../../helper';
import Button from './../../../../shared/Button/Button';
import config from '../../../../../config';
import { format } from 'date-fns';

const CandidatePreviewCardMobile = ({ data, onClose, onFavorite }) => {
  const {
    hired_datetime,
    is_favourite,
    resume_highlights,
    profile_status,
    motto,
    companies
  } = data || {};

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.25 }}
      className="h-full p-4 rounded-8 d-flex flex-column pointer no-tap-highlight">
      <div className="flex flex-col h-full min-h-0 mb-20 overflow-auto rounded-lg no-scrollbar border-1 border-[#a2c1d7]">
        <div className="relative transition-all h-[330px]">
          <img
            draggable={false}
            src={data?.avatar}
            className="top-0 left-0 object-cover w-full h-full"
          />
          <div className="absolute top-0 flex justify-between w-full">
            <div onClick={onClose} className="p-4 cursor-pointer">
              <CloseIcon className="fill-white" />
            </div>
            {profile_status === config.candidate.profileStatus.HIRED && (
              <div className="p-3">
                <BriefCaseIcon className="fill-white" />
              </div>
            )}
          </div>
          <div className="absolute bottom-0 left-0 w-full p-2 pb-4 text-white z-1 bg-gradient-to-t from-black/55">
            <div className="flex justify-between">
              <div>
                <p className="text-[21px]">
                  {data?.first_name + '-' + data?.last_name}
                </p>
                <p className="text-[14px]">{data?.tech_stack}</p>
                <p className="text-[12px] mt-2">
                  {constructLocationString(
                    data?.city,
                    data?.state,
                    data?.country
                  )}
                </p>
              </div>
              <div className="flex items-end mx-2 cursor-pointer">
                <Tippy content="Add to favorites" placement="top">
                  <HeartIcon
                    onClick={onFavorite}
                    className={`action-icon heart-icon state-transition  ${
                      is_favourite ? ' fill-td-primary' : ''
                    }`}
                  />
                </Tippy>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-4 bg-[#F8FBFD]">
          <div className="flex items-center justify-between border-b border-[#b7d2dd] pb-2 mb-4">
            {hired_datetime &&
              profile_status === config.candidate.profileStatus.HIRED && (
                <div className="text-[11px] flex flex-col items-center">
                  <BriefCaseIcon className="fill-td-primary" />
                  <p>{format(new Date(hired_datetime), 'MM/dd/yyyy')}</p>
                </div>
              )}
            <div className="text-[11px]">
              <div className="flex justify-center">
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 1 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 2 ? ' active' : ''
                  }`}
                />
                <StarIcon
                  className={`star-icon${
                    data?.culture_fit >= 3 ? ' active' : ''
                  }`}
                />
              </div>
              <p className="text=[#526c80] font-semibold">Culture Fit</p>
            </div>
            <div className="text-center text-[11px]">
              <p className="text=[#526c80] font-semibold">Experience</p>
              <span>{`${data?.experience} ${
                data?.experience > 0 ? 'Years' : 'Year'
              }`}</span>
            </div>
            <div className="flex items-center gap-2 text-[11px]">
              <ChevronIcon />
              <span className="text=[#526c80] font-semibold">
                Tech
                <br />
                Assessment
              </span>
            </div>
          </div>
          <div className="relative">
            <div className="absolute top-0 right-0 flex justify-end w-full gap-4">
              {linkSocialIcon(data?.github_url, GithubIcon, 'Github')}
              {linkSocialIcon(
                data?.linkedin_url,
                LinkedinIcon,
                'Linkedin'
              )}
              {linkSocialIcon(
                data?.portfolio_url,
                PortfolioIcon,
                'Portfolio'
              )}
            </div>
            <div>
              <p className="text-[#526c80] font-semibold mb-2">
                Education
              </p>
              <div className="flex flex-col">
                {!!data?.educations?.length &&
                  data.educations.map((education, index) => (
                    <div key={index} className="flex flex-col">
                      <span className="text-[13px] degree">
                        {education?.specialization}
                      </span>
                      <span className="font-semibold institution">
                        {education?.university + ', ' + education?.course}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div>
            <p className="text-[#526c80] font-semibold mb-2 mt-4">
              Resume Highlights
            </p>
            <p className="text-[14px] line-clamp-5">
              {resume_highlights?.split('\n').map((paragraph) => (
                <p key={paragraph}>{paragraph}</p>
              ))}
            </p>
            {Boolean(motto) && (
              <div className="text-[#509ec6] font-semibold my-4">
                “{motto}”
              </div>
            )}
          </div>
          {Boolean(companies?.length) && (
            <div className="my-4">
              <p className="text-[#526c80] font-semibold mb-2">
                Worked For
              </p>
              <div>
                <div className="flex gap-4">
                  {companies.map(({ company_logo_url, company_name }) => (
                    <div className="max-h-[35px]" key={company_name}>
                      <img
                        src={company_logo_url}
                        height="35"
                        className="max-h-[35px]"
                        alt={company_name}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4">
            <div>
              <p className="text-[#526c80] font-semibold mb-2">Skills</p>
              {!!data?.skills?.length && (
                <ul className="ml-4 list-disc">
                  {data?.skills?.map(({ id, skill_name }) => (
                    <li key={id}> {skill_name}</li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <p className="text-[#526c80] font-semibold mb-2">Hobbies</p>
              <ul className="ml-4 list-disc">
                {data?.hobbies?.map(({ id, hobby_name }) => (
                  <li key={id}>{hobby_name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-between gap-2 my-8 education-row">
            <a
              href={data?.resume}
              className="candidate-no-underline align-self-center"
              target="_blank"
              rel="noopener noreferrer nofollow">
              <Button
                className="view-resume-btn"
                icon={() => <ViewResumeIcon className="min-w-[16px]" />}
                title="VIEW FULL RESUME"
              />
            </a>
            <Button
              className="view-resume-btn"
              icon={() => <HeartIcon className="min-w-[16px]" />}
              title={is_favourite ? 'UNFAVORITE' : 'FAVORITE'}
              onClick={onFavorite}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

CandidatePreviewCardMobile.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired
};

export default CandidatePreviewCardMobile;
