import { Avatar, Typography } from '@mui/material';
import { differenceInMinutes, format } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { INTERVIEW_STATUSES } from '../../../constants/common';
import {
  useRescheduleInterviewMutation,
  useUpdateClientInterviewStatusQuery
} from '../../../queries/Interview';
import { openInNewTab } from '../../../utils/common';
import CampaignDetailsModal from '../../campaign/CampaignDetailsModal';
import ConfirmationModal from '../../shared/ConfirmationModal';
import InterviewMenu from '../InterviewMenu';
import InterviewModal from '../InterviewModal';
import InterviewSchedulingModal from '../InterviewSchedulingModal';
import LaunchMeetingButton from './LaunchMeetingButton';
import './UpcomingInterviewListItem.scss';

const UpcomingInterviewListItem = ({ data }) => {
  const [isInterviewModalOpened, setIsInterviewModalOpened] =
    useState(false);
  const [isCampaignDetailsModalOpened, setIsCampaignDetailsModalOpened] =
    useState(false);
  const [
    isInterviewReschedulingModalOpen,
    setIsInterviewReschedulingModalOpen
  ] = useState(false);
  const [
    isInterviewCancellationModalOpen,
    setIsInterviewCancellationModalOpen
  ] = useState(false);
  const [interviewStatus, setInterviewStatus] = useState(data?.status);
  const {
    firstName,
    lastName,
    date,
    avatar,
    host_room_code,
    campaign_name,
    campaign_id,
    candidate_id,
    interview_date
  } = data || {};

  useEffect(() => {
    setInterviewStatus(data?.status);
  }, [data?.status]);

  const { mutateAsync: updateInterviewStatusInServer } =
    useUpdateClientInterviewStatusQuery();

  const { mutateAsync: rescheduleInterview } =
    useRescheduleInterviewMutation();

  const interviewLink = '/interview/' + host_room_code;

  const dateObj = new Date(date);

  const handleOpenInterviewModal = () => {
    setIsInterviewModalOpened(true);
  };

  const handleOpenInterviewReschedulingModal = () => {
    setIsInterviewReschedulingModalOpen(true);
  };

  const handleOpenInterviewCancellationModal = () => {
    setIsInterviewCancellationModalOpen(true);
  };

  const timeDifferenceOfNowAndInterview = differenceInMinutes(
    new Date(interview_date),
    new Date()
  );

  //if the interview time has passed 2 hours, we should NOT SHOW the launch meeting button
  const isHiddenMeetingLaunch = timeDifferenceOfNowAndInterview <= -120;

  //if the interview is not coming soon within 30 mins, we should DISABLE the launch meeting button
  const isDisabledMeetingLaunch = timeDifferenceOfNowAndInterview > 30;

  return (
    <div
      className="upcoming-interview-list-item"
      style={{
        opacity: interviewStatus === INTERVIEW_STATUSES.CANCELLED ? 0.5 : 1
      }}>
      <div className="flex justify-between items-center mb-2">
        <p className="h-[30px] max-w-[190px] truncate !text-td-first-frost bg-[#255782] content-center rounded-r-2xl font-semibold text-sm px-4">
          {campaign_name}
        </p>
        <div className="flex gap-1">
          {(!interviewStatus ||
            interviewStatus === INTERVIEW_STATUSES.AWAITING) && (
            <p className="text-sm font-bold !text-td-first-frost">
              Awaiting
            </p>
          )}
          {interviewStatus === INTERVIEW_STATUSES.SCHEDULED && (
            <p className="text-xs font-bold flex flex-col !text-td-first-frost">
              <span>{format(dateObj, 'MM/dd/yy')}</span>
              <span>{format(dateObj, 'hh:mm a')}</span>
            </p>
          )}
          {interviewStatus === INTERVIEW_STATUSES.SLOT_REQUESTED && (
            <p className="text-sm font-bold !text-td-first-frost">
              Declined
            </p>
          )}
          {interviewStatus === INTERVIEW_STATUSES.VOTED_UP && (
            <p className="text-sm font-bold !text-td-first-frost">
              Positive
            </p>
          )}
          {interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN && (
            <p className="text-sm font-bold !text-td-first-frost">
              Negative
            </p>
          )}
          {interviewStatus === INTERVIEW_STATUSES.INTERVIEWED && (
            <p className="text-sm font-bold !text-td-first-frost">
              Completed
            </p>
          )}
          <InterviewMenu
            openInterviewModal={handleOpenInterviewModal}
            openInterviewReschedulingModal={
              handleOpenInterviewReschedulingModal
            }
            openInterviewCancellationModal={
              handleOpenInterviewCancellationModal
            }
            data={{
              interviewLink,
              status: interviewStatus,
              isDisabledMeetingLaunch,
              isHiddenMeetingLaunch
            }}
          />
        </div>
      </div>
      <div className="upcoming-interview-list-item__header ml-3">
        <Avatar
          alt={firstName + ' ' + lastName}
          src={avatar}
          sx={{
            mr: 1.5,
            height: 42,
            width: 42
          }}
        />
        <span className="text-base font-semibold text-truncated w-48 mr-auto">
          {firstName} {lastName}
        </span>
        {(!interviewStatus ||
          interviewStatus === INTERVIEW_STATUSES.AWAITING) && (
          <img
            src="/campaign-status/awaiting-status.png"
            className="w-[33px] h-[32px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
        {interviewStatus === INTERVIEW_STATUSES.SLOT_REQUESTED && (
          <img
            src="/campaign-status/declined-status.png"
            className="w-[31px] h-[31px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
        {interviewStatus === INTERVIEW_STATUSES.VOTED_UP && (
          <img
            src="/campaign-status/positive-status.png"
            className="w-[27px] h-[34px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
        {interviewStatus === INTERVIEW_STATUSES.VOTED_DOWN && (
          <img
            src="/campaign-status/negative-status.png"
            className="w-[27px] h-[34px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
        {interviewStatus === INTERVIEW_STATUSES.SCHEDULED && (
          <img
            src="/campaign-status/scheduled-status.png"
            className="w-[40px] h-[42px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
        {interviewStatus === INTERVIEW_STATUSES.INTERVIEWED && (
          <img
            src="/campaign-status/completed-status.png"
            className="w-[40px] h-[42px] flex-shrink-0 absolute -right-3 top-1/2 transform -translate-y-1/2"
          />
        )}
      </div>
      {(interviewStatus === INTERVIEW_STATUSES.SCHEDULED ||
        interviewStatus === INTERVIEW_STATUSES.RESCHEDULED) &&
        !isHiddenMeetingLaunch && (
          <div className="upcoming-interview-list-item__button-wrapper">
            <LaunchMeetingButton
              onClick={() => openInNewTab(interviewLink)}
              isDisabled={isDisabledMeetingLaunch}
            />
          </div>
        )}
      <ConfirmationModal
        message={
          <Typography>
            Do you want to cancel the interview with the candidate
            <b>{` ${firstName} ${lastName}`}</b>?
          </Typography>
        }
        isOpen={isInterviewCancellationModalOpen}
        onConfirm={async () => {
          setIsInterviewCancellationModalOpen(false);
          setInterviewStatus(INTERVIEW_STATUSES.CANCELLED);
          updateInterviewStatusInServer({
            interview_id: data.id,
            status: INTERVIEW_STATUSES.CANCELLED
          });
        }}
        onCancel={() => {
          setIsInterviewCancellationModalOpen(false);
        }}
      />
      {isInterviewModalOpened && (
        <InterviewModal
          onClose={() => setIsInterviewModalOpened(false)}
          interviewId={data.id}
          openCampaignDetails={() => {
            setIsInterviewModalOpened(false);
            setIsCampaignDetailsModalOpened(true);
          }}
          updateInterviewStatus={(status) => setInterviewStatus(status)}
        />
      )}
      {isCampaignDetailsModalOpened && (
        <CampaignDetailsModal
          campaignId={campaign_id}
          onClose={() => setIsCampaignDetailsModalOpened(false)}
        />
      )}
      {isInterviewReschedulingModalOpen && (
        <InterviewSchedulingModal
          onClose={() => {
            setIsInterviewReschedulingModalOpen(false);
          }}
          noOfDates={2}
          primaryCtaText="RESCHEDULE"
          title="RESCHEDULE THE INTERVIEW"
          candidateName={`${firstName} ${lastName}`}
          onSubmit={async (dates) => {
            setInterviewStatus(INTERVIEW_STATUSES.AWAITING);
            return await rescheduleInterview({
              dates,
              candidate_id,
              campaign_id
            });
          }}
        />
      )}
    </div>
  );
};

UpcomingInterviewListItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default UpcomingInterviewListItem;
