import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { cn } from '@src/utils';

const Dropdown = ({
  onChange,
  options,
  disabled,
  defaultOption,
  selectedOption,
  className,
  wrapperClassName,
  labelKey = 'label'
}) => {
  const buttonRef = useRef();
  const [anchorElement, setAnchorElement] = useState(null);
  const [currentSelectedOption, setCurrentSelectedOption] =
    useState(defaultOption);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setAnchorElement(buttonRef.current);
  };

  useEffect(() => {
    setCurrentSelectedOption(selectedOption);
  }, [selectedOption]);

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div className={cn(wrapperClassName, 'relative')}>
      <Grid
        container
        ref={buttonRef}
        alignItems="center"
        gap="0.25rem"
        onClick={handleClick}
        className={className}
        sx={{
          cursor: disabled ? 'default' : 'pointer',
          background: disabled ? '#f5f5f5' : '#fff',
          padding: '0.15rem 0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #509ec6',
          height: '2.375rem',
          display: 'flex'
        }}>
        <Grid item className="w-full truncate">
          <p className="text-left truncate text-td-suez-canal">
            {currentSelectedOption?.[labelKey]}
          </p>
        </Grid>
        <Grid item className="min-w-4">
          <KeyboardArrowDownRoundedIcon
            style={{
              width: '1rem',
              height: '1rem',
              fill: '#509ec6',
              transform: anchorElement ? 'rotate(180deg)' : 'rotate(0)'
            }}
          />
        </Grid>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            minWidth: buttonRef.current && buttonRef.current.offsetWidth,
            maxWidth: 200
          }
        }}>
        {options &&
          Object.values(options).map((option) => (
            <MenuItem
              key={option.value}
              selected={currentSelectedOption === option}
              onClick={() => {
                handleClose();
                setCurrentSelectedOption(option);
                onChange(option);
              }}>
              {option?.[labelKey]}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default Dropdown;
