import { createSelector } from 'reselect';

const dashboardSlice = (state) => state.DashboardSlice;

export const selectActivityFeedFilter = createSelector(
  dashboardSlice,
  (state) => {
    return state.activityFeedFilter;
  }
);

export const selectShowMobileFilters = createSelector(
  dashboardSlice,
  (state) => {
    return state.showMobileFilters;
  }
);
