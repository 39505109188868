import { Box, Button, Grid, Typography } from '@mui/material';
import Modal from '../../shared/Modal';

const InterviewConfirmModal = ({ onClose }) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      maxWidth="500px"
      width="100%"
      className="z-10">
      <Box>
        <Typography sx={{ textAlign: 'center' }}>
          We have sent your proposed interview times to the candidate. Once
          the candidate chooses an interview time, we will email you a
          calendar invite for the interview and the interview status will
          be displayed in the interviews component on the dashboard screen.
        </Typography>
        <Box marginTop={2}>
          <Grid container justifyContent="center" gap="40px">
            <Grid item>
              <Button
                sx={{ width: '7rem' }}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default InterviewConfirmModal;
