import { Box, Divider, Grid, Typography } from '@mui/material';
import SmilingFaceIcon from '../../../../../assets/images/smiling-face-active.svg';
import MehFaceIcon from '../../../../../assets/images/meh-face-active.svg';
import SadFaceIcon from '../../../../../assets/images/sad-face-active.svg';
import { INTERVIEW_SENTIMENTS } from '../../../../../constants/common';
import './AISummaryTab.scss';

const AISummaryTab = (props) => {
  const {
    interviewSummary,
    candidateSentimentSummary,
    candidateSentiment
  } = props;
  return (
    <Box className="ai-summary-tab">
      <Box padding="1rem 2rem">
        <Grid container gap="1rem">
          <Grid item width="100%">
            <Typography
              color="#509ec6"
              fontSize={16}
              fontWeight={600}
              marginBottom="4px">
              AI Generated Candidate Sentiment
            </Typography>
            <Typography>
              {candidateSentimentSummary ??
                "The candidate sentiment's summary is being generated"}
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignItems="center" display="flex" minWidth="3rem">
            <img
              src={(() => {
                switch (candidateSentiment) {
                  case INTERVIEW_SENTIMENTS.NORMAL:
                    return MehFaceIcon;
                  case INTERVIEW_SENTIMENTS.BAD:
                    return SadFaceIcon;
                  case INTERVIEW_SENTIMENTS.GOOD:
                  default:
                    return SmilingFaceIcon;
                }
              })()}
              alt="Candidate sentiment"
              width={40}
              height={40}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box padding="0.5rem 2rem">
        <Typography
          color="#509ec6"
          fontSize={16}
          fontWeight={600}
          marginBottom="4px">
          AI Generated Interview Summary
        </Typography>
      </Box>
      <Box marginBottom="1rem">
        <Grid container>
          <Grid item>
            <img
              src="/ai-vertical-banner.png"
              alt="AI Vertical Banner"
              height="334px"
              width="54px"
            />
          </Grid>
          <Grid
            item
            width="100%"
            height="20rem"
            margin="0 2rem 0 1rem"
            position="relative">
            <Typography className="summary-content max-h-[500px] overflow-auto">
              {interviewSummary
                ? interviewSummary
                    .split('\n')
                    .map((paragraph) => <p key={paragraph}>{paragraph}</p>)
                : 'The interview summary is being generated'}
            </Typography>
            <Box className="pointer"></Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AISummaryTab;
