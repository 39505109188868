import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useRef, useState } from 'react';
import { INTERVIEW_DATE_FILTER_OPTIONS } from '../../../constants/common';
import { Grid, Typography } from '@mui/material';

const InterviewDateFilter = ({ onDateRangeChange }) => {
  const buttonRef = useRef();
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    INTERVIEW_DATE_FILTER_OPTIONS.THIS_WEEK
  );
  const handleClick = () => {
    setAnchorElement(buttonRef.current);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div className="relative w-[110px]">
      <Grid
        container
        ref={buttonRef}
        alignItems="center"
        gap="0.25rem"
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          background: '#fff',
          padding: '0.15rem 0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #509ec6',
          justifyContent: 'space-between'
        }}>
        <Grid item>
          <Typography color="#265d84">{selectedOption.label}</Typography>
        </Grid>
        <Grid item>
          <KeyboardArrowDownRoundedIcon
            style={{
              width: '1rem',
              height: '1rem',
              fill: '#509ec6',
              transform: anchorElement ? 'rotate(180deg)' : 'rotate(0)'
            }}
          />
        </Grid>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {Object.values(INTERVIEW_DATE_FILTER_OPTIONS).map((option) => (
          <MenuItem
            key={option.value}
            selected={selectedOption === option}
            onClick={() => {
              handleClose();
              if (option.getDateRange) {
                setSelectedOption(option);
                onDateRangeChange(option.getDateRange());
              }
            }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default InterviewDateFilter;
